<template>
  <div class="card col-xl-12 col-lg-12">
    <div class="card-header card-header-flex flex-column">
      <a-tabs
        class="kit-tabs"
        :default-active-key="activeKey"
        @change="callback"
      >
        <a-tab-pane :tab="$t('comptabilite.fournisseur')" key="1" />
        <a-tab-pane :tab="$t('comptabilite.typeDepense')" key="2" />
        <a-tab-pane :tab="$t('comptabilite.typeRecette')" key="3" />
      </a-tabs>
    </div>
    <div class="card-body">
      <keep-alive>
        <component v-bind:is="currentTabComponent" :key="loadKey"></component>
      </keep-alive>
    </div>
  </div>
</template>
<script>
/*eslint-disable*/
import depense from "./components/depense";
import providers from "./components/providers";
import recette from "./components/recette";
export default {
  name: "compatibilite",
  components: {
    depense,
    providers,
    recette,
  },
  data() {
    return {
      activeKey: "1",
      loadKey: 0,
    };
  },
  computed: {
    currentTabComponent: function () {
      switch (this.activeKey) {
        case "2":
          return "depense";
        case "1":
          return "providers";
        case "3":
          return "recette";
        default:
          return "buildings";
      }
    },
  },
  methods: {
    callback: function (key) {
      this.activeKey = key;
      this.loadKey += 1;
    },
  },
};
</script>
